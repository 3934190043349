import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Header from './components/Header';
import Footer from './components/Footer';
import TopPage from './pages/TopPage';
import AccessoryPage from './pages/AccessoryPage';
import PartyManagementPage from './pages/PartyManagementPage';

const theme = createTheme({
  palette: {
    primary: {
      main: '#1976d2',
    },
    secondary: {
      main: '#dc004e',
    },
  },
  typography: {
    fontFamily: 'Roboto, Arial, sans-serif',
  },
});

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Helmet>
          <title>【クルスタ】ティンクルスターナイツ 新生学園 図書館</title>
          <meta name="description" content="ティンクルスターナイツ(クルスタ)のキャラクター、装飾品、パーティー編成などを管理するWebツールです。キャラクターや装飾品は所持チェッカーや所持レアリティ管理ができます。詳細でキャラクターデータも閲覧できます。" />
          <meta property="og:title" content="【クルスタ】ティンクルスターナイツ 新生学園 図書館" />
          <meta property="og:description" content="ティンクルスターナイツ(クルスタ)のキャラクター、装飾品、パーティー編成などを管理するWebツールです。キャラクターや装飾品は所持チェッカーや所持レアリティ管理ができます。詳細でキャラクターデータも閲覧できます。" />
          <meta property="og:image" content="img/ogp.png" />
          <meta property="og:url" content="https://twinklestarknights-library.pages.dev" />
          <meta name="keywords" content="ティンクルスターナイツ, クルスタ, 図書館, ツール, キャラクター所持チェッカー, クルスタ図鑑" />
          <meta name="robots" content="index, follow" />
        </Helmet>
        <Header />
        <Switch>
          <Route path="/" exact component={TopPage} />
          <Route path="/accessories" component={AccessoryPage} />
          <Route path="/partymanagement" component={PartyManagementPage} />
        </Switch>
        <Footer />
      </Router>
    </ThemeProvider>
  );
};

export default App;